
import { defineComponent } from "@vue/runtime-core";
MouseEvent
export default defineComponent({
  name: "FAQ",
  methods: {
    accordion: function (event: PointerEvent) { 
      var div_obj = (event.target as HTMLInputElement).nextElementSibling as HTMLInputElement;
      if (div_obj!.style.maxHeight != ""){
        div_obj!.style.maxHeight = "";    
      }
      else {
        div_obj!.style.maxHeight = div_obj.scrollHeight + "px";
      }
    }
  }
});
